<template>
	<div class="download-container">
		<div class="download-info-row">
			<div class="download-title"><img src="@/assets/gb/downloadedfile@2x.png" alt="" /> Download started</div>
			<div class="download-memo">
				When the download completed, please click the apk file to install.
			</div>

			<div class="download-rating-coantiner">
				<div class="rating-title"><img src="@/assets/like.png" alt="" /> Please rate us</div>

				<div class="rating-control-row">
					<div class="star-item" :class="{ active: i <= ratingStar }" @click="rate(i)" v-for="i in 5" :key="i"></div>
				</div>
			</div>

			<div class="collect-me-container" v-if="showCollect" @click="showCollect = false">
				<div class="collect-me-row">
					<img src="@/assets/close-icon.png" class="collect-close" @click="showCollect = false" alt="" />
					<h4>Thank you for your feedback</h4>
					<img class="collect-pic" src="@/assets/gb/collect.png" alt="" />
					<p>It is highly recommended to bookmark our website or join our official channel to timely obtain the latest version updates to avoid product expiration</p>
				</div>
			</div>

			<div class="download-gif">
				<img src="@/assets/gb/donwl.gif" alt="" />
			</div>
		</div>

		<div class="ad-container">
			<div class="tuimod-text">
				The best tools make WhatsApp more useful & poewrful.
			</div>

			<div class="big-tuimod has-exp" exp-data="vml">
				<div class="big-tuimod-info-row">
					<div class="big-tuimod-info">
						<div class="big-tuimod-title">
							Status Downloader Pro
						</div>
						<div class="big-tuimod-des">
							More Videos & Status YouTube, Facebook, Tiktok, Instagram
						</div>
					</div>
					<div class="download-btn" @click="downloadAPK(vmlapk, 'vml')">
						DOWNLOAD
					</div>
				</div>
			</div>

			<div class="tuimod-load-list">
				<div class="tuimod-load-list-item has-exp" :exp-data="data.name" v-for="(data, i) in moreItem" :key="i">
					<div class="ad-img" :style="{ backgroundImage: `url(${data.icon})`, backgroundSize: '100%' }"></div>
					<div class="tuimod-load-list-row">
						<div class="tuimod-load-list-info">
							<div class="tuimod-load-list-title">
								{{ data.title }}
							</div>
							<div class="tuimod-load-list-des">
								{{ data.advertise }}
							</div>
						</div>
						<div class="tuimod-load-list-download-btn" @click="downloadAPK(data.apk, data.name)">
							DOWNLOAD
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import "@/css/gb/pcdownload.scss";
import "@/css/gb/mdownload.scss";
export default {
	name: "download",
	data() {
		return {
			pageName: "download",
			vmlapk: "https://cdn.gbwhatsapp.download/app/android/apk/vidtmate-3.0074.apk",
			moreItem: [
				{
					name: "Netflix",
					title: "Netflix Unlock Mod",
					icon: require("@/assets/gb/Netflix.png"),
					advertise: "Download Latest Movies & TV Series",
					apk: "https://www.cdns.world/vml/apk/launch/Netflix_Free_1.6.4_gb.apk",
				},
			],
			ratingStar: null,
			showCollect: false,
			gbIOS: false,

			appLogo: null,
			apk: null,
			activeAPK: null,

			from: null,
			filename: null,
			domain: null,
			appName: null,
		};
	},
	beforeMount() {
		var apps = this.$global.GetQueryString("apps") || "ob";
		const from = this.$global.GetQueryString("from");
		const filename = this.$global.GetQueryString("filename");
		const isOffical = this.$global.GetQueryString("isOffical");
		const domain = this.$global.GetQueryString("domain");
		const appName = this.$global.GetQueryString("appName");

		console.log(apps, from, filename, isOffical, domain, appName);

		[this.from, this.filename, this.domain, this.appName] = [
			from,
			filename,
			domain,
			appName,
		];

		// this.$global.download('https://apk.obwhatsappdownload.com/OBWhatsApp_2.23.8.408_up_07_08_2034_sec_sign.apk');

		
		if (from && filename) {
			console.log(`ready to get ${from} apk`);
			this.getAPK();
		} else if (isOffical != 0) {
			console.log("ready to get official apk");
			this.getOfficalApk(2);
		} else {
			console.log("ready to get default apk");
			this.getOfficalApk();
		}

		this.appName = this.$store.state[apps].name;
		this.appLogo = this.$store.state[apps].logo;
		var isIOS;
		if (typeof navigator !== "undefined") {
			// 在浏览器环境中执行的代码
			isIOS =
				/iPad|iPhone|iPod/.test(navigator.userAgent) &&
				!window.MSStream;
		} else {
			isIOS = false;
		}

		if (isIOS && this.appName == "GBWhatsApp") {
			this.gbIOS = true;
		}
	},
	watch: {
		activeAPK(newVal) {
			// 在值变化时执行后续操作
			if (newVal !== null) {
				// 执行你想要的操作
				console.log("获取成功");
				console.log(this.activeAPK);
				this.$logEvent("show_downloadstart");
				this.$logEvent(`click_${this.appName}_download`);
				this.$global.download(this.activeAPK);
			}
		},
	},
	methods: {
		getAPK() {
			this.$server
				.getAPPInfo({
					promotionChannel: this.from,
					promotionName: this.filename,
				})
				.then((res) => {
					console.log(res);
					if (res.code == 200) {
						this.activeAPK = res.data.apk;
					} else {
						this.activeAPK = process.env.VUE_APP_GBLINK;
					}
				});
		},
		getOfficalApk(set = 1) {
			this.$server
				.getOfficalApk({ domain: this.domain, appName: this.appName })
				.then((res) => {
					console.log(res);
					if (res.code == 200) {
						console.log("apk getted");
						console.log(res.data.promotion.apk);
						if (set == 1) {
							//取自随机的
							this.activeAPK = res.data.promotion.apk;
						}
						if (set == 2) {
							this.activeAPK = res.data.apk;
						}
					} else {
						this.activeAPK = process.env.VUE_APP_GBLINK;
					}
				});
		},
		rate(i) {
			if (this.$store.state.voteScore) {
				return;
			}
			console.log(i);
			this.ratingStar = i;
			this.rateScore(i);
		},
		rateScore(score) {
			this.$server.saveappScore({ as: "gbw", s: score }).then((res) => {
				console.log(res);
				this.$store.commit("UPDATE_VOTING", score);
				console.log(this.$store.state.gb);
				this.showCollect = true;
				setTimeout(() => {
					this.showCollect = false;
				}, 8000);
			});
		},
		downloadAPK(apk, name) {
			this.$global.download(apk);
			this.$logEvent(`click_downloadstart_${name}`, {
				platform: "mobile",
			});
		},
	},
};
</script>
